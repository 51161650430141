import '../App.css';
import {
    Button, f7, f7ready, Icon,
    Page,
} from "framework7-react";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";

function Startup() {
    f7ready(() => {});
    const { i18n, t } = useTranslation();
    const handleMainBtn = () => {
        window.Telegram.WebApp.MainButton.showProgress((leave = true) => {})
        window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        f7.views.main.router.navigate('/register');
        window.Telegram.WebApp.MainButton.hideProgress()
    }

    useEffect( () => {
        window.Telegram.WebApp.MainButton.text = t("continueBtn");
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.BackButton.hide();
        console.log(window.Telegram.WebApp.initData)

        return (() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        })
    }, []);

    return (
        <Page bgColor='white' onPageAfterOut={() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        }}>
            <div style={{textAlign: 'center', padding: 20}}>
                <h1>{t("welcome")}</h1>
                <p style={{fontSize: '1.2em',
                    wordWrap: 'break-word',
                    overflowWrap:'break-word',
                    whiteSpace: 'normal'}}>
                    <span style={{color: "#4E80EE", fontWeight: 'bold'}}>
                        Selluz Motors
                    </span>{t('welcomeDescription')}
                </p>
            </div>
            <div className="card-content">
                <div className="list media-list no-safe-areas" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <ul style={{marginTop: -30, padding: 0, listStyle: 'none', width: '100%'}}>
                        <li className="item-content" style={{display: 'flex', alignItems: 'center', padding: '5px 5px'}}>
                            <div className="item-media" style={{marginLeft: '1.1em'}}>
                                <Icon f7="car_fill" size="30px" style={{color: "#4E80EE", }}></Icon>
                            </div>
                            <div className="item-inner" style={{flex: 1}}>
                                <div className="item-title-row">
                                    <div className="item-title">{t('descriptionQuality')}</div>
                                </div>
                                <div style={{marginTop: 5, fontSize: '0.9em'}}>
                                    {t('qualityDesc')}
                                </div>
                            </div>
                        </li>
                        <li className="item-content" style={{display: 'flex', alignItems: 'center', padding: '5px 5px'}}>
                            <div className="item-media" style={{marginLeft: '0.9em'}}>
                                <Icon f7="person_crop_circle_badge_checkmark" size="35px" style={{color: "#4E80EE"}}></Icon>
                            </div>
                            <div className="item-inner" style={{flex: 1}}>
                                <div className="item-title-row">
                                    <div className="item-title">{t('descriptionSafe')}</div>
                                </div>
                                <div style={{marginTop: 5, fontSize: '0.9em'}}>
                                    {t('safeDesc')}
                                </div>
                            </div>
                        </li>
                        <li className="item-content" style={{display: 'flex', alignItems: 'center', padding: '5px 5px'}}>
                            <div className="item-media" style={{marginLeft: '0.9em'}}>
                                <Icon f7="money_dollar_circle_fill"  size="35px" style={{color: "#4E80EE"}}></Icon>
                            </div>
                            <div className="item-inner" style={{flex: 1}}>
                                <div className="item-title-row">
                                    <div className="item-title">{t('descriptionCost')}</div>
                                </div>
                                <div style={{marginTop: 5, fontSize: '0.9em'}}>
                                    {t('costDesc')}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <Button onClick={() => f7.views.main.router.navigate("/lang")} large style={{width: '80%', maxWidth: '300px', margin: '20px auto'}}>{t('chooseLang')}</Button>
                </div>
            </div>
        </Page>
    );
}

export default Startup;
