
import {
    App,
    BlockFooter, BlockHeader,
    BlockTitle, f7, f7ready,
    Link,
    List,
    ListInput, ListItem, Page,
} from "framework7-react";
import React, {useEffect, useReducer, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../store/store";
import {getPartner, updatePartner} from "../api/partner";
import {useTranslation} from "react-i18next";

function ProfileForm() {
    f7ready(() => {
    });
    const {t, i18n} = useTranslation();
    const initialState = {
        errors: {}
    }
    function formReducer(state, action) {
        switch (action.type) {
            case 'field':
                return {
                    ...state,
                    [action.fieldName]: action.payload,
                };
            case 'setErrors':
                return {
                    ...state,
                    errors: action.payload,
                };
            default:
                return state;
        }
    }

    const [state, localDispatch] = useReducer(formReducer, initialState);

    const validateForm = () => {
        const errors = {};
        if (!name.trim()) errors.name = 'Введите имя';
        if (phoneNumber.trim() === "+998" || Number.isNaN(Number(phoneNumber.slice(1))) || phoneNumber.length < 13) errors.phoneNumber = "Некорректный номер телефона";

        localDispatch({ type: 'setErrors', payload: errors });
        return Object.keys(errors).length === 0;
    };

    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)

    const [name, setName] = useState(null);
    const [username, setUsername] = useState('')
    const [phoneNumber, setPhoneNumber] = useState("+998")
    const [fileURL, setFileURL] = useState(null)
    const [birthday, setBirthday] = useState()
    const [region, setRegion] = useState("")

    //init inputs
    useEffect(() => {
        setName(user.name)
        setPhoneNumber(user.phoneNumber)
        setBirthday(user.birthday)
        setFileURL(user.fileURL)
        setRegion(user.region)
    }, [])
    const handleMainBtn = async () => {
        if (validateForm()) {
            const res = await updatePartner({
                registered_name: name,
                username: username,
                phone_number: phoneNumber,
                region: region,
            })
            console.log(res)
            dispatch(setUser({name, fileURL, birthday, username, phoneNumber, region}))
            f7.views.main.router.navigate('/main');
        }
    }

    const handleBackBtn = () => {
        f7.views.main.router.navigate('/main');
    }

    useEffect(() => {


        const user = window.Telegram.WebApp.initDataUnsafe.user;


        if (user) {
            let telegramId = user.id;
            setUsername(user.username);
        }


        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = t('saveChangesBtn');
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);
        window.Telegram.WebApp.BackButton.show();

        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn)
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn)
        }
    }, [handleMainBtn])


    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (!value.startsWith('+998')) {
            setPhoneNumber('+998');
        } else {
            setPhoneNumber(value);
        }
    };
    let handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileURL(URL.createObjectURL(file))
        }
    };

    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [handleMainBtn])






    return (
        <Page onPageAfterOut={() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn)
        }}>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>{t('personalData')}</BlockTitle>

            <List strongIos dividersIos insetIos>
                <ListInput
                    errorMessageForce={state.errors.name}
                    errorMessage={state.errors.name}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={t('registerName')}
                />
            </List>
            <BlockFooter>{t('registerName')}</BlockFooter>


            <List strongIos dividersIos insetIos>
                <ListInput
                    errorMessageForce={state.errors.phoneNumber}
                    errorMessage={state.errors.phoneNumber}
                    type={"tel"}
                    inputmode={"numeric"}
                    placeholder="Номер телефона"
                    maxlength={13}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                />
            </List>
            <BlockFooter>{t('registerPhone')}</BlockFooter>


            <List key={`${name}`}  strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={t('registerRegion')}
                          smartSelect
                >
                    <select value={region} onChange={(e) => setRegion(e.target.value)} name={region}
                    >
                        <option value='' disabled defaultChecked></option>
                        <option value="Ташкент">{t('regions.tashkent')}</option>
                        <option value="Ташкентская обл">{t('regions.tashkent_obl')}</option>
                        <option value="Фергана">{t('regions.fergana')}</option>
                        <option value="Андижан">{t('regions.andijan')}</option>
                        <option value="Наманган">{t('regions.namangan')}</option>
                        <option value="Бухара">{t('regions.bukhara')}</option>
                        <option value="Навои">{t('regions.navoi')}</option>
                        <option value="Хорезм">{t('regions.khorezm')}</option>
                        <option value="Сурхандарья">{t('regions.surkhandarya')}</option>
                        <option value="Кашкадарья">{t('regions.kashkadarya')}</option>
                        <option value="Сырдарья">{t('regions.syrdarya')}</option>
                        <option value="Джиззак">{t('regions.jizzakh')}</option>
                        <option value="Самарканд">{t('regions.samarkand')}</option>
                        <option value="Каракалпакстан">{t('regions.karakalpakstan')}</option>
                    </select>
                </ListItem>
            </List>
            {!localStorage.getItem('requestSent') && (
                <div style={{cursor: 'pointer'}} onClick={() => f7.views.main.router.navigate("/legalentityform")}>
                    <List strongIos dividersIos insetIos>
                        <ListItem style={{display: 'flex', justifyContent: 'center', width: "100%"}}>
                            <label style={{color:  '#007AFF'}}>{t('becomeLegalEntity')}</label>
                        </ListItem>
                    </List>
                </div>
            )}

        </Page>
    );
}

export default ProfileForm;