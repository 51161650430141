import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next)
    .init({
        fallbackLng: "uz",
        lng: "uz",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            ru: {
                translation: {
                    gotoMarket: 'Перейти в маркетплейс',
                    interfaceLang: 'Язык интерфейса',
                    welcome: 'Добро пожаловать! 👋',
                    welcomeDescription: ' - маркетплейс по покупке и продаже автомобилей в Узбекистане 🇺🇿',
                    chooseLang: '🇷🇺 Выберите язык',
                    continueBtn: 'ПРОДОЛЖИТЬ',
                    saveBtn: 'Сохранить',
                    descriptionQuality: 'Мгновенное добавление',
                    qualityDesc: 'Добавьте авто за несколько минут с привлекательным оформлением',
                    descriptionSafe: 'Легкий контакт',
                    safeDesc: 'Получите сообщения и звонки от покупателей, а также возможность оставить контакты для обратной связи',
                    descriptionCost: 'Быстрая сделка',
                    costDesc: 'Все подробности об авто в одной карточке, никаких повторных объяснений',
                    topDesc: 'Продвигает автомобиль в верхние ряды маркетплейса',
                    top: 'Топ',

                    register: "Регистрация",
                    registerName: 'Ваше имя',
                    registerPhone: 'Ваш номер телефона для связи',
                    registerWriteName: 'Укажите ваше имя',
                    registerWritePhone: 'Укажите номер телефона для связи',
                    registerPickDate: 'Дата рождения',
                    registerPickYourDate: 'Укажите дату вашего рождения',
                    registerRegion: 'Регион',
                    registerPickRegion: 'Укажите регион вашего проживания',
                    registerWriteUsername: 'Ваш username в телеграмме',
                    goToCarFormBtn: 'Перейти к добавлению машины',
                    chooseBirthdayErr: 'Укажите свой день рождения',
                    chooseRegionErr: 'Укажите регион',
                    chooseUsernameErr: 'Укажите username',
                    chooseValidPhone: 'Укажите корректный номер телефона',


                    yetEmpty: 'Сейчас в вашем автопарке пусто.\n Нажмите «Добавить машину» и начните заполнять!',
                    carAddition: 'Оформление автомобиля',
                    brand: 'Марка',
                    model: 'Модель',
                    position: 'Позиция',
                    provideCarData: 'Укажите комплектацию вашего автомобиля',
                    color: 'Цвет',
                    carColor: 'Цвет вашего автомобиля',
                    transmission: 'Коробка передач',
                    chooseYear: 'Указать год',
                    pickYear: 'Укажите год выпуска автомобиля',
                    cost: 'Цена',
                    mileage: 'Пробег',
                    description: 'Дополнительная информация',
                    enableTop: 'Пожалуйста, заполните все поля чтобы включить опцию Топ',
                    priceSum: 'Цена в сумах:',
                    writeDescription: 'Введите дополнительные данные об автомобиле',
                    pickImage: 'Выберите фото',
                    img:'Изображение',
                    canTrade: 'Возможность торгов',
                    canTradeDesc: 'Позволяет покупателям договариваться о цене',
                    provideCarDataErr: "Укажите полную комплекатцию автомобиля",
                    pickColorErr: 'Укажите цвет',
                    pickTransmissionErr: 'Укажите коробку передач',
                    pickYearErr: 'Укажите год',
                    pickCostErr: 'Укажите корректную цену',
                    pickMileageErr: 'Укажите пробег',
                    pickDescriptionErr: 'Введите описание',
                    pickImageErr: 'Укажите фото',
                    ready: 'Готово',

                    myCars: 'Мои машины',
                    myData: 'Мои данные',
                    notifications:'Заявки на торг',


                    enterText: 'Введите текст',
                    personalData: 'Личные данные',
                    addCarBtn: 'Добавить машину',
                    deleteCar: 'Удалить машину',
                    saveChangesBtn: 'Сохранить',
                    options:'Опции',
                    optics: 'Оптика',
                    saletype: 'Тип продажи',
                    fueltype: 'Тип топлива',
                    bodytype: 'Кузов',
                    drive: 'Привод',
                    outside:'Снаружи',
                    media: 'Медиа',
                    cabin: 'Салон',

                    som: 'сум',
                    becomeLegalEntity: 'Добавить автосалон',
                    legalEntityForm: 'Ваш автосалон',
                    dealerName: 'Название автосалона',
                    chooseDealerName: 'Укажите название вашего автосалона',
                    location: 'Локация',
                    chooseLocation: 'Укажите локацию вашего автосалона',
                    chooseLogo: 'Логотип автосалона',
                    logoDesc: 'Укажите логотип вашего автосалона',
                    sendRequestBtn: 'Подать заявку',
                    pickLocationBtn: 'Указать эту локацию',
                    views: 'Просмотры',

                    carDeletion: 'Удаление машины',
                    carDelDesc: 'Пожалуйста укажите причину удаления',
                    deleteText: 'Удалить машину',
                    reason: 'Причина',
                    deleteDescription: 'Пожалуйста укажите причину деактивации',
                    notificationsEmpty: 'На данный момент новые запросы от клиентов еще не поступили',


                    regions: {
                        tashkent: "г. Ташкент",
                        tashkent_obl: "Ташкентская область",
                        fergana: "Ферганская область",
                        samarkand: "Самаркандская область",
                        bukhara: "Бухарская область",
                        andijan: "Андижанская область",
                        namangan: "Наманганская область",
                        navoi: "Навоийская область",
                        khorezm: "Хорезмская область",
                        surkhandarya: "Сурхандарьинская область",
                        kashkadarya: "Кашкадарьинская область",
                        syrdarya: "Сырдарьинская область",
                        jizzakh: "Джизакская область",
                        karakalpakstan: "Республика Каракалпакстан",
                    },












                },
            },
            uz: {
                translation: {
                    topDesc: 'Avtomobilni online bozorda yuqori qatorlariga ko\'taradi',
                    top: 'Top',
                    gotoMarket: "Marketplacega o'tish",
                    priceSum: 'Narxi so’mda:',
                    som: 'so’m',
                    interfaceLang: 'Interface tili',
                    welcome: 'Xush kelibsiz! 👋',
                    welcomeDescription: ' - O’zbekistonda avtomobillarni sotib olish va sotish uchun online bozor 🇺🇿',
                    chooseLang: '🇺🇿 Tilni tanlang',
                    continueBtn: 'DAVOM ETISH',
                    saveBtn: 'Saqlash',
                    descriptionQuality: 'Tezda qo’shish',
                    qualityDesc: 'Avtomobilni bir necha daqiqada batafsil ma\'lumot bilan qo’shing',
                    descriptionSafe: `Qulay aloqa`,
                    safeDesc: 'Xaridorlardan xabarlar va qo’ng’iroqlar oling, va, ularning aloqa ma’lumotlariga ega bo’ling',
                    descriptionCost: 'Tezda sotish',
                    costDesc: 'Barcha ma’lumotlar bitta joyda, takroriy tushuntirishlarsiz',
                    enterText: 'Tezda sotish',

                    register: "Roʻyxatdan oʻtish",
                    registerName: 'Ismingiz',
                    registerWriteName: 'Ismingizni kiriting',
                    registerPhone: 'Telefon raqamingiz',
                    registerWritePhone: 'Aloqa uchun telefon raqamingizni kiriting',
                    registerPickDate: 'Tug’ilgan sana',
                    registerPickYourDate: `Tug’ilgan sanangizni kiriting`,
                    registerRegion: 'Hududingiz',
                    registerPickRegion: 'Yashash hududingizni kiriting',
                    registerWriteUsername: 'Telegramdagi foydalanuvchi ismingiz(@username)',
                    goToCarFormBtn: `Mashinani qo’shishga o’tish`,
                    chooseBirthdayErr: `Tug'ilgan kuningizni kiriting`,
                    chooseRegionErr: 'Hududingizni belgilang',
                    chooseUsernameErr: 'Telegramdagi foydalanuvchi ismingiz(@username) belgilang',
                    chooseValidPhone: `Iltimos, to'g'ri telefon raqamingiznii kiriting`,

                    deleteText: 'Mashinani ochirish',
                    deleteDescription: `Iltimos, o'chirish sababini yozing`,
                    reason: 'Sabab',

                    notificationsEmpty: 'Hozirgi vaqtda mijozlardan yangi savdo uchun so\'rovlar kelib tushmagan',



                    bodytype: 'Кuzov',
                    drive: 'Privod',

                    yetEmpty: `Hozir sizning avtoparkingiz bo’sh.\n «Mashina qo’shish» tugmasini bosib, to’ldirishni boshlang!`,
                    ready: 'Tayyor',
                    carAddition: `Mashinani qo'shish`,
                    brand: 'Marka',
                    model: 'Model',
                    position: 'Pozitsiya',
                    provideCarData: 'Mashinangizni komplektatsiyasini belgilang',
                    color: 'Rang',
                    carColor: 'Mashinangizni rangi',
                    transmission: 'Uzatish qutisi',
                    chooseYear: 'Yilini kiriting',
                    img:'Surat',
                    pickYear: 'Avtomobilning ishlab chiqarilgan yilini kiriting',
                    cost: 'Narx',
                    mileage: 'Probeg',
                    description: `Qo'shimcha ma'lumot`,
                    writeDescription: `Avtomobil haqidagi qo’shimcha ma’lumotlarni kiriting`,
                    pickImage: `Mashinangizni rasmini qo'shing`,
                    canTrade: 'Savdolashish imkoniyati',
                    canTradeDesc: 'Xaridorlarga narxni kelishib olish imkonini beradi',
                    provideCarDataErr: "Iltimos, mashinaning to'liq komplektatsiyasini ko'rsating",
                    pickColorErr: 'Rangni belgilang',
                    pickTransmissionErr: 'Uzatish qutisini belgilang',
                    pickYearErr: 'Yilni kiriting',
                    pickCostErr: 'Toʻgʻri narxni kiriting',
                    pickMileageErr: 'Probegni kiriting',
                    pickDescriptionErr: `Qo'shimcha ma'lumotni kiriting`,
                    pickImageErr: `Mashinangizni rasmini qo'shing`,

                    myCars: 'Mening mashinalarim',
                    myData: `Mening ma'lumotlarim`,
                    notifications:'Savdo uchun takliflar',
                    views: 'Ko’rishlar',

                    enableTop: 'Top funksiyani yoqish uchun barcha ma\'lumotlarni kiriting',

                    personalData: `Shaxsiy ma’lumotlar`,
                    addCarBtn: `Mashina qo’shish`,
                    deleteCar: `Mashinani o'chirish`,
                    saveChangesBtn: `Saqlash`,
                    options:'Opsiyalar',
                    optics: 'Optika',
                    saletype: 'Sotuv yoli',
                    fueltype: 'Yoqilgi turi',

                    outside:'Tashiqari',
                    media: 'Media',
                    cabin: 'Salon',

                    becomeLegalEntity: 'Avtosalon qo\'shish',
                    legalEntityForm: `Sizning avtosaloningiz`,
                    dealerName: 'Avtosalon nomi',
                    chooseDealerName: 'Avtosaloningiz nomini kiriting',
                    location: 'Joylashuv',
                    chooseLocation: 'Avtosalon joylashgan joyni belgilang',
                    chooseLogo: 'Avtosalon logotipi',
                    logoDesc: 'Avtosaloningizni logotipini kiriting',
                    sendRequestBtn: 'Ariza yuborish',
                    pickLocationBtn: 'Bu joylashuvni belgilang',

                    regions: {
                        tashkent: "Toshkent shahri",
                        tashkent_obl: "Toshkent viloyati",
                        fergana: "Farg‘ona viloyati",
                        samarkand: "Samarqand viloyati",
                        bukhara: "Buxoro viloyati",
                        andijan: "Andijon viloyati",
                        namangan: "Namangan viloyati",
                        navoi: "Navoiy viloyati",
                        khorezm: "Xorazm viloyati",
                        surkhandarya: "Surxondaryo viloyati",
                        kashkadarya: "Qashqadaryo viloyati",
                        syrdarya: "Sirdaryo viloyati",
                        jizzakh: "Jizzax viloyati",
                        karakalpakstan: "Qoraqalpog‘iston Respublikasi",
                    },
                },
            },
        },
    });

export default i18next;