import React, {useEffect, useState} from 'react';
import {getPartner, Login, updatePartner} from "../api/partner";
import axios from "axios";
import {App, f7, f7ready, Page, Preloader} from "framework7-react";
import {BeatLoader} from "react-spinners";
import {getHandbook} from "../api/car";
import {useTranslation} from "react-i18next";

const Splash = () => {

    const {i18n} = useTranslation();
    const [uniqueID, setUniqueID] = useState(Date.now().toString(36))
    const [token, setToken] = useState(null)
    const [refresh, setRefresh] = useState(null)

    const [brandOptions, setBrandOptions] = useState([]);
    // const [modelOptions, setModelOptions] = useState([]);
    const [transmissionOptions, setTransmissionOptions] = useState([]);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [colorOptions, setColorOptions] = useState([]);


    useEffect(() => {
        window.Telegram.WebApp.MainButton.color ="#4E80EE"
        console.log(window.Telegram.WebApp.initData);
    }, [])

    useEffect(() => {
        window.Telegram.WebApp.expand()

        const fetchHandbooks = async () => {
            const handbookTypes = [
                { key: 'brandOptions', type: 'brand' },
                { key: 'equipmentOptions', type: 'equipment' },
                { key: 'colorOptions', type: 'color' },
                { key: 'transmissionOptions', type: 'transmissiontype' },
                { key: 'deleteReason', type: 'deletereason' },
                { key: 'addonOptions', type: 'options' },
                { key: 'saletype', type: 'saletype' },
                { key: 'fueltype', type: 'fueltype' },
                { key: 'optics', type: 'optics' },
                { key: 'bodyType', type: 'bodytype' },
                { key: 'driveType', type: 'drivetype' },
                { key: 'cabin', type: 'cabin' },
                { key: 'media', type: 'media' },
                { key: 'outside', type: 'outside' },
            ];

            try {
                const results = await Promise.all(
                    handbookTypes.map(({ type }) => getHandbook(type))
                );

                results.forEach((result, index) => {
                    const { key } = handbookTypes[index];
                    localStorage.setItem(key, JSON.stringify(result));
                });

            } catch (error) {
                console.error('handbooks error:', error);
            }
        };



        async function login() {
                try {
                    const res = await Login({init_data: window.Telegram.WebApp.initData})
                    console.log(res)
                    if (res.status === 'Username is required') {
                        window.Telegram.WebApp.close()
                    }
                    if (res.status === 'success') {
                        setToken(res.access)
                        setToken(res.refresh)
                        localStorage.setItem('token', res.access);
                        localStorage.setItem('refresh', res.refresh);
                        axios.defaults.headers.common['Authorization'] = `Bearer ${res.access}`
                        await fetchHandbooks()
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            login().then(() => {
                getPartner().then(res => {
                    if (window.Telegram.WebApp.initDataUnsafe.user.username !== res.username) {
                        updatePartner({username: window.Telegram.WebApp.initDataUnsafe.user.username})
                    }
                    if (res.fully_registered === true) {
                        if (res.first_car_added) {
                            i18n.changeLanguage(res.language)
                            console.log('fully registered', uniqueID)
                            if (window.Telegram.WebApp.initDataUnsafe.start_param) {
                                f7.views.main.router.navigate(`/${window.Telegram.WebApp.initDataUnsafe.start_param}/`)
                                return;
                            }

                            f7.views.main.router.navigate('/main',
                                {
                                    props: {
                                        uniqueID: uniqueID
                                    }
                                }
                            );
                        } else {
                            f7.views.main.router.navigate('/simplecarform')
                        }
                    }
                    else {
                        console.log("here" + res)
                        f7.views.main.router.navigate('/');
                    }
                }).catch(err => {
                    console.log(err)
                })
            })



    }, []);



    return (
        <Page>
            <div style={{display: 'flex', height:'100%', alignItems: 'center', justifyContent: 'center'}}>
                <Preloader size={20} color="#4E80EE"/>
            </div>
        </Page>
    );
};

export default Splash;
