import axios from 'axios';
import apiLink from "./apiLink";


export const getPartner = async () => {
    try {
        const res = await axios.get(`${apiLink}/api/partner/`)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const Login = async (data) => {
    try {
        delete axios.defaults.headers.common['Authorization'];

        const res = await axios.post(`${apiLink}/api/token/`, data)

        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const updatePartner = async (data) => {
    try {
        return await axios.patch(`${apiLink}/api/partner/`, data)
    } catch (e) {
        console.log(e)
    }
}

export const createPartner = async (data) => {
    try {
        return await axios.put(`${apiLink}/api/partner/`, data)
    } catch (e) {
        console.log(e)
    }
}

export const legalPartner  = async (data) => {
    try {
        return await axios.post(`${apiLink}/api/legal_partner/`, data)
    } catch (e) {
        console.log(e)
    }
}

export const getNotifications  = async () => {
    try {
        return await axios.get(`${apiLink}/api/car/notification`)
    } catch (e) {
        console.log(e)
    }
}

export const retrieveNotification  = async (id, data) => {
    try {
        return await axios.put(`${apiLink}/api/car/notification/${id}/`, data)
    } catch (e) {
        console.log(e)
    }
}






