import {
    App,
    BlockFooter, BlockHeader,
    BlockTitle, f7, f7ready,
    Link,
    List,
    ListInput, ListItem, Page, TextEditor, Toggle,
} from "framework7-react";
import React, {useCallback, useEffect, useReducer, useRef, useState} from "react";
import camera from "../../assets/icons/camera.svg";

import {createCar, getHandbook, getMinPrice} from "../../api/car";
import {useTranslation} from "react-i18next";
import {updatePartner} from "../../api/partner";


function SimpleCarForm() {
    f7ready(() => {
    });

    const {t, i18n} = useTranslation();

    const pickerDevice = useRef(null);
    const validateForm = () => {
        const errors = {}
        if (!brand.value) errors.brand = 'Укажите brand'
        if (!carModel.value && !(brand.value === "other")) errors.carModel = 'Укажите модель';
        if (!color.value) errors.color = t('pickColorErr');
        if (!description.trim()) errors.description = t('pickDescriptionErr');
        if (!transmissionType.value) errors.transmission = t('pickTransmissionErr');
        if (!year) errors.year = t('pickYearErr')
        if (!price.trim() || isNaN(+price) || price > 999999 || price < 200) errors.price = t('pickCostErr')
        if (!mileage.trim() || isNaN(+mileage) || mileage > 999999) errors.mileage = t('pickMileageErr')
        localDispatch({type: 'setErrors', payload: errors})
        console.log(errors)
        return Object.keys(errors).length === 0;
    };

    const [brandOptions, setBrandOptions] = useState(JSON.parse(localStorage.getItem('brandOptions')));
    const [transmissionOptions, setTransmissionOptions] = useState(JSON.parse(localStorage.getItem('transmissionOptions')));
    const [equipmentOptions, setEquipmentOptions] = useState(JSON.parse(localStorage.getItem('equipmentOptions')));
    const [colorOptions, setColorOptions] = useState(JSON.parse(localStorage.getItem('colorOptions')));




    const [brand, setBrand] = useState({ value: '', text: ''});
    const [carModel, setCarModel] = useState({ value: '', text: '' });
    const [carPosition, setCarPosition] = useState({ value: '', text: '' });
    const [transmissionType, setTransmissionType] = useState({ value: '', text: '' })
    const [color, setColor] = useState({ value: '', text: '' })
    const [description, setDescription] = useState("")
    const [year, setYear] = useState('')
    const [isTrade, setIsTrade] = useState(false)
    const [price, setPrice] = useState("")
    const [mileage, setMileage] = useState("")
    const [fileURL, setFileURL] = useState([])
    const [image, setImage] = useState([])
    const [minPrice, setMinPrice] = useState('')
    const formData = new FormData();


    const [years, setYears] = useState(() => {
        const newYears = [];
        for (let i = 1980; i < 2025; i++) {
            newYears.push(i);
        }
        return newYears;
    });

    const initialState = {
        errors: {},
    };

    function formReducer(state, action) {
        switch (action.type) {
            case 'field':
                return {
                    ...state,
                    [action.fieldName]: action.payload,
                };
            case 'setErrors':
                return {
                    ...state,
                    errors: action.payload,
                };
            default:
                return state;
        }
    }

    const [state, localDispatch] = useReducer(formReducer, initialState);







    const onPageInit = () => {
        pickerDevice.current = f7.picker.create({
            inputEl: '#demo-picker-device1',
            cols: [
                {
                    textAlign: 'center',
                    values: years,
                    onChange: function (picker, value) {
                        setYear(value)
                    }
                },
            ],

        });
        pickerDevice.current.setValue([2022])

    }

    useEffect(() => {
        if (pickerDevice.current) {
            pickerDevice.current.destroy();
        }

        pickerDevice.current = f7.picker.create({
            inputEl: '#demo-picker-device1',
            cols: [
                {
                    textAlign: 'center',
                    values: years,
                    onChange: function (picker, value) {
                        setYear(value);
                    }
                },
            ],
        });
        pickerDevice.current.setValue([year || 2022])
    }, [brand,carPosition, carModel, state.errors, color]);


    const getModelsForSelectedBrand = (brandId) => {
        const selectedBrand = brandOptions.find(b => b.id === parseInt(brandId));
        return selectedBrand ? selectedBrand.models : [];
    };

    const getEquipmentForSelectedModel = (modelId) => {
        return equipmentOptions.filter(equipment => equipment.model_id === parseInt(modelId));
    };



    const handleMainBtn = async () => {
        if (validateForm()) {

            window.Telegram.WebApp.MainButton.showProgress((leave = false) => {
            })
            fileURL.forEach((image, index) => {
                formData.append('images', image);
            });
            formData.append('year', year)
            formData.append('mileage', mileage)
            formData.append('price_usd', price)
            formData.append('description', description)
            formData.append('brand_id', brand.value)
            formData.append('model_id', carModel.value)
            formData.append('equipment_id', carPosition.value)
            formData.append('color_id', color.value)
            formData.append('is_bargain', isTrade)
            formData.append('transmission_type_id', transmissionType.value)
            const res = await createCar(formData);
            const res2 = await updatePartner({
                first_car_added: true,
            })
            console.log(res2)
            f7.views.main.router.navigate('/main', {props: {showToast: true}})
            window.Telegram.WebApp.MainButton.hideProgress()
        }



    }
    function handleBackBtn() {
        f7.views.main.router.navigate('/register', {props: {shouldFetchData: true}});
    }

    useEffect(() => {
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = t('continueBtn')
        window.Telegram.WebApp.BackButton.show();
    }, []);


    useEffect(() => {
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);
        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }
    }, [handleMainBtn])

    const handleFileChange = (e) => {
        const newFiles = e.target.files;
        const filesArray = Array.from(newFiles)

        if (fileURL.length < 3) {
            setFileURL([...fileURL,newFiles[0]]);
        } else {
            fileURL.pop()
            setFileURL([...fileURL, newFiles[0]])
        }
        setImage([...image, ...filesArray])
    };

    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [brand, handleMainBtn])

    const handleDelete = (ind) => {
        setFileURL(fileURL.filter((item) => item !== fileURL[ind]))
        setImage(image.filter((item) => item !== image[ind]))
    }

    const getSvgDataUrl = (colorCode) => {
        const svg = `
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="${colorCode}"/>
    </svg>
  `;
        const encodedSvg = btoa(svg.trim());
        return `data:image/svg+xml;base64,${encodedSvg}`;
    };

    useEffect(() => {
        const fetchMinPrice = async () => {
            const data = await getMinPrice(brand.value, carModel.value, carPosition.value, year, mileage, color.value);
            setMinPrice(data.min_price)
        }

        if (brand.value && carModel.value && carPosition.value && year && mileage && color.value) {
            fetchMinPrice()
        }
    }, [brand.value, carModel.value, carPosition.value, year, mileage, color.value])



    return (
        <Page onPageAfterOut={() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }} onPageInit={onPageInit}>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>{t('carAddition')}</BlockTitle>

            <List style={{marginTop: 20}} strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                    searchbar: true,
                    searchbarDisableButton: false,
                    appendSearchbarNotFound: 'Ничего не найдено',
                    searchbarPlaceholder: 'Search brand...',
                }}
                          title={t('brand')}
                          smartSelect
                >
                    <select value={brand.value} onChange={(e) => {setBrand({
                        value: e.target.value,
                        text: e.target.options[e.target.selectedIndex].text
                    }); setCarModel({value: '', text: ''}); setCarPosition({value: '', text: ''});}}>
                        <option value="" defaultChecked disabled></option>
                        {brandOptions.map(brand => (
                            <option
                                key={brand.id}
                                value={brand.id}>{brand.name}
                            </option>
                        ))}
                    </select>
                </ListItem>
            </List>
            {brand.value && (
                <div>
                    <List key={`${brand.value}-model-list`} style={{ marginTop: -10 }} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                            searchbar: true,
                            searchbarDisableButton: false,
                            appendSearchbarNotFound: 'Ничего не найдено',
                            searchbarPlaceholder: 'Search model...',
                        }}
                                  title={t('model')}
                                  smartSelect
                        >
                            <select value={carModel.value} onChange={(e) => setCarModel({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            })}>
                                <option defaultChecked disabled value=""></option>
                                {getModelsForSelectedBrand(brand.value).map(model => (
                                    <option key={model.id} value={model.id}>{model.name}</option>
                                ))}
                            </select>
                        </ListItem>
                    </List>


                </div>
            )}
            {carModel.value && (
                <div>
                    <List key={`${carModel.value}-equipment-list`} style={{ marginTop: -10 }} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title={t('position')}
                                  smartSelect
                        >
                            <select value={carPosition.value} onChange={(e) => setCarPosition({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            })}>
                                <option defaultChecked disabled value=""></option>
                                {equipmentOptions.map(equipment => (
                                    <option key={equipment.id} value={equipment.id}>
                                        {i18n.language === 'ru' ? equipment.name_ru : equipment.name_uz}
                                    </option>
                                ))}
                            </select>
                        </ListItem>
                    </List>
                </div>
            )}
            {((state.errors.brand || state.errors.carPosition ||  state.errors.carModel) && (!brand.value || !carModel.value || !carPosition.value))  && <BlockFooter style={{marginTop: -25, color:"#FF3B30"}}>{t('provideCarDataErr')}</BlockFooter>}



            <List style={{marginTop: -10}} strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={t('color')}
                          smartSelect
                >
                    <select onChange={(e) => setColor({
                        value: e.target.value,
                        text: e.target.options[e.target.selectedIndex].text
                    }) } name="car">
                        <option></option>

                        {colorOptions.map(color => (
                            <option
                                key={color.id}
                                data-option-image={getSvgDataUrl(color.code)}
                                value={color.id}
                            >
                                {i18n.language === 'ru' ? color.name_ru : color.name_uz}
                            </option>
                        ))}
                    </select>
                </ListItem>
            </List>
            {state.errors.color && !color.value && <BlockFooter style={{position: 'absolute',color:"#FF3B30"}}>{state.errors.color}</BlockFooter>}

            <List strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={t('transmission')}
                          smartSelect
                >
                    <select onChange={(e) => setTransmissionType({
                        value: e.target.value,
                        text: e.target.options[e.target.selectedIndex].text
                    }) } name="transmission">
                        <option></option>

                        {transmissionOptions.map(trans => (
                            <option
                                key={trans.id}
                                value={trans.id}
                            >
                                {i18n.language === 'ru' ? trans.name_ru : trans.name_uz}
                            </option>
                        ))}
                    </select>
                </ListItem>
            </List>
            {state.errors.transmission && !transmissionType.value && <BlockFooter style={{position: 'absolute',color:"#FF3B30"}}>{state.errors.transmission}</BlockFooter>}

            <List strongIos dividersIos insetIos>
                <ListInput
                    errorMessage={state.errors.year}
                    errorMessageForce={state.errors.year}
                    value={year}
                    type="text"
                    placeholder={t('chooseYear')}
                    readonly
                    inputId="demo-picker-device1"
                />
            </List>
            <BlockFooter>{t('pickYear')}</BlockFooter>


            <List strongIos dividersIos insetIos>
                <ListItem className={state.errors.mileage && 'item-input-invalid'}>
                    <div className="item-input-wrap">
                        <input
                            type="text"
                            inputMode="numeric"
                            placeholder={t('mileage')}
                            value={mileage}
                            onChange={(e) => {if (!Number.isNaN(+e.target.value)) setMileage(e.target.value)}}
                        />
                        {state.errors.mileage && <div className="input-error-message">{state.errors.mileage}</div>}
                    </div>
                    <div className="item-after">KM</div>
                </ListItem>
            </List>
            <List strongIos dividersIos insetIos>
                <ListInput
                    errorMessage={state.errors.description}
                    errorMessageForce={state.errors.description}
                    type="textarea"
                    placeholder={t('description')}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    maxlength={200}
                    minlength={50}
                />
            </List>
            <BlockFooter>{t('writeDescription')}</BlockFooter>

            <div className="carform-ctn">
                <List strongIos dividersIos insetIos>
                    <ListItem className={"file-input-ctn"}>
                        <img
                            width={25}
                            src={camera}
                        />
                        <label style={{marginLeft: 10}} className="custom-file-label" htmlFor="file-input1">
                            {t('pickImage')}
                        </label>

                        <input
                            id="file-input1"
                            type="file"
                            className="custom-file-input"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </ListItem>


                    {fileURL[0] && (fileURL.map( (url, index) => (<ListItem

                        title={`${t('img')} ${index + 1}`}


                    >
                        <Link slot="after" onClick={() => handleDelete(index)}>
                            <i className="icon f7-icons" style={{color: 'gray'}}>multiply</i>
                        </Link>
                        <img
                            alt="car_img"
                            slot="media"
                            style={{ borderRadius: '8px' }}
                            src={URL.createObjectURL(url)}
                            width="50"
                            height="50"
                        />
                    </ListItem>)))}
                </List>
                {state.errors.fileURL && !fileURL && <BlockFooter style={{position: 'absolute',color:"#FF3B30"}}>{state.errors.fileURL}</BlockFooter>}

                <List strongIos dividersIos insetIos>
                    <ListItem className={state.errors.price && 'item-input-invalid'}>
                        <div className="item-input-wrap">
                            <input
                                type="text"
                                inputMode="numeric"
                                placeholder={t('cost')}
                                value={price}
                                onChange={(e) => {if (!Number.isNaN(+e.target.value)) setPrice(e.target.value)}}
                            />
                            {state.errors.price && <div className="input-error-message">{state.errors.price}</div>}
                        </div>
                        <div className="item-after">$</div>
                    </ListItem>
                </List>
                {minPrice && <BlockFooter>Минимальная цена: {minPrice}</BlockFooter>}


                <List strongIos dividersIos insetIos>
                    <ListItem>
                        <span>{t('canTrade')}</span>
                        <Toggle onToggleChange={(checked) => setIsTrade(checked)}/>
                    </ListItem>
                </List>
                <BlockFooter>{t('canTradeDesc')}</BlockFooter>
            </div>
        </Page>
    );
}

export default SimpleCarForm;